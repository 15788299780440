.calendar {
    margin: auto;
    width: 100%;
    /* max-width: 600px; */
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #a3d6fa;
    padding: 5px;
    /* border-top: 1px solid #ccc; */
    border-bottom: 1px solid #ccc;
  }

  .calendar-month{
    display: flex;
    align-items: center;
  }
  
  .calendar-header h1 {
    margin: 0;
  }
  
  .calendar-header button {
    background-color:#195faa;
    color: white;
    border: none;
    padding: 5px 5px;
    border-radius: 3px;
    cursor: pointer;

    float: left;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .calendar-header h3{
    float: left;
    padding: 0 30px;
    width: 190px;
    text-align: center;
  }
  
  .calendar-header button:hover {
    background-color: #8bb7e6;
  }
  
  .calendar-header button:disabled {
    background-color: #ccc;
    cursor: default;
  }
  
  /* table {
    width: 100%;
    border-collapse: collapse;
  }
  
  td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    
    cursor: pointer;
  } */

  .calendar-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
  
  .day-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
  .disableMoveButton{
        cursor: not-allowed;
        background-color: rgb(229, 229, 229) !important;
        pointer-events:none;
  }
  .day-header {
    /* border-top: 1px solid #ccc; */
    font-weight: bold;
    font-size: 12px;
    /* padding: 5px; */
    /* margin-bottom: 5px; */
  }
  
  .current-date {
    background-color: #4caf50;
    color: white;
    font-size: 12px;
  }
  .selectable-date{
    font-size: 12px;
  }
  .selected-date {
    background-color: #76b4eb ;
    color: white;
    font-size: 12px;
  }
  
  .selectable-date:hover {
    background-color: #e0e0e0;
    cursor: pointer;
    font-size: 12px;
  }
  
  .unselectable-date {
    color: #ccc;
    pointer-events: none;
    font-size: 12px;
  }
  @media (max-width:790px) {
    .day-container {
      height: 40px;
      width: 37px;
  }
  .day-header {
    font-weight: bold;
    font-size: 11px;
}
.unselectable-date {

  font-size: 10px;
}.current-date {
  font-size: 10px;
}
.selectable-date{
  font-size: 10px;
}
.selected-date{
  font-size: 10px;
}
  }


  @media (max-width:500px) {
  .calendar-header{
    flex-direction: column;
  }
  .calendar-header .button-next-available{
    margin-top: 10px;
  }
}