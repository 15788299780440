.ant-space, .ant-picker, .ant-select{
    width: 100%;
}
.ant-picker{
    background-color: rgba(221, 221, 221, 0.678);
}
.ant-modal-header{
    background-color: #0074b7;
}
.ant-modal-title{
    color: white;

}
.filter-btn{
   background-color:  #0074b7;
   color: white
}
.close-btn, .reset-btn{
   background-color:  #d1d1d1a6;
   color: black
}
.event-filters{
    position: relative;
}
.event-filters .filter-option{
    position: absolute;
    right: 15px;
    top: 10px;
}
.ant-picker-input input::placeholder {
    color: rgba(0, 0, 0, 0.507);
}
.ant-input::placeholder{
    font-weight: 600;
}
.ant-select-selection-placeholder {
  font-weight: 600
}
.covid-msg{
    font-size: 15px;
}
.active-filter{
    background-color: #26467c;
    color: rgb(221, 221, 221)
}
.help-section{

}
.help-link{
    cursor: pointer;
    text-decoration: underline;
    color: #4f86e3;
    font-weight: 500;
}
.help-OR-text{
    font-weight: 600;
}
.ant-modal{
    padding: 0px;
}
.observValue{
    display: block; 
    overflow-y: auto;
    width: 100%;
    white-space: break-spaces;
    color:black;
}